import { Link } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";
import BaseLayout from "../../components/static/BaseLayout";
import MainHeading from "../../components/static/MainHeading";


const Publications = () => {

  return (
    <BaseLayout
      pageTitle="Scientific Publications | ReInHerit Digital Hub"
    >
      <MainHeading breadCrumb={[{label:"About", value:"/about"},{label:"Publications", value:"/about/publications"} ]}>Scientific Publications</MainHeading>
      <Container fluid className="bg-white shadow p-sm-3 p-md-4 rounded">
        <p>
          The H2020 ReInHerit project published its results in a series of scientific publications. Here we present the list of publications, with links to their Open Access versions.
        </p>
        <ol>
            <li>Alberto Baldrati, Marco Bertini, Tiberio Uricchio, & Alberto Del Bimbo. (2022, September 27). Effective conditioned and composed image retrieval combining CLIP-based features. <a href="https://doi.org/10.1109/CVPR52688.2022.02080" target="_blank" rel="noreferrer">https://doi.org/10.1109/CVPR52688.2022.02080</a></li>
            <li>Alberto Baldrati, Marco Bertini, Tiberio Uricchio, & Alberto Del Bimbo. (2022, August 23). Conditioned and composed image retrieval combining and partially fine-tuning CLIP-based features. <a href="https://doi.org/10.1109/CVPRW56347.2022.00543" target="_blank" rel="noreferrer">https://doi.org/10.1109/CVPRW56347.2022.00543</a></li>
            <li>Lorenzo Agnolucci, Leonardo Galteri, Marco Bertini, & Alberto Del Bimbo. (2022, October 10). Restoration of Analog Videos Using Swin-UNet. <a href="https://doi.org/10.1145/3503161.3547730" target="_blank" rel="noreferrer">https://doi.org/10.1145/3503161.3547730</a></li>
            <li>Maria Giovanna Donadio, Filippo Principi, Andrea Ferracani, Marco Bertini, & Alberto Del Bimbo. (2022, October 10). Engaging Museum Visitors with Gamification of Body and Facial Expressions. <a href="https://doi.org/10.1145/3503161.3547744" target="_blank" rel="noreferrer">https://doi.org/10.1145/3503161.3547744</a></li>
            <li>Pietro Bongini, Federico Becattini, & Alberto Del Bimbo. (2023, February 15). Is GPT-3 All You Need for Visual Question Answering in Cultural Heritage?. <a href="https://doi.org/10.1007/978-3-031-25056-9_18" target="_blank" rel="noreferrer">https://doi.org/10.1007/978-3-031-25056-9_18</a></li>
            <li>Alberto Baldrati, Marco Bertini, Tiberio Uricchio, & Alberto Del Bimbo. (2022, October 30). Exploiting CLIP-Based Multi-modal Approach for Artwork Classification and Retrieval. <a href="https://doi.org/10.1007/978-3-031-20302-2_11" target="_blank" rel="noreferrer">https://doi.org/10.1007/978-3-031-20302-2_11</a></li>
            <li>Federico Becattini, Pietro Bongini, Luana Bulla, Alberto Del Bimbo, Ludovica Marinucci, Misael Mongiovì, & Valentina Presutti. (2023). VISCOUNTH: A Large-Scale Multilingual Visual Question Answering Dataset for Cultural Heritage. <a href="https://doi.org/10.1145/3590773" target="_blank" rel="noreferrer">https://doi.org/10.1145/3590773</a></li>
            <li>Konstantinos Roussos, & Irini Stamatoudi. (2022). Management Issues for Cultural Heritage Institutions (This is a draft chapter. The final version is available in Research Handbook on Intellectual Property and Cultural Heritage edited by Irini Stamatoudi, published in 2022, Edward Elgar Publishing Ltd. <a href="https://doi.org/10.4337/9781800376915.00032" target="_blank" rel="noreferrer">https://doi.org/10.4337/9781800376915.00032</a></li>
            <li>Stamatoudi, I., & Roussos, K. (2023). Dealing with Illicit Tradein Cultural Objects in the Context of Cultural Heritage Management for Museums. Santander Art and Culture Law Review, 2/2023((9)), 87–116. <a href="https://doi.org/10.4467/2450050XSNR.23.024.18644" target="_blank" rel="noreferrer">https://doi.org/10.4467/2450050XSNR.23.024.18644</a></li>
            <li>Harfst, J., Sandriester, J., Zuanni, C., & Krottmaier, S. (2022). Nachhaltigkeit als disruptives Konzept im Kultursektor: Digitalisierung und nachhaltiges Kulturerbe-Management. Ökologisches Wirtschaften, 37(4), 30–34. <a href="https://doi.org/10.14512/OEW370430" target="_blank" rel="noreferrer">https://doi.org/10.14512/OEW370430</a></li>
            <li>Stamatoudi, I., & Roussos, K. (2024). Aspects of Informatics in Cultural Heritage Management. Informatics Studies, 11(1), 9–18. <a href="https://doi.org/10.5281/zenodo.11175164" target="_blank" rel="noreferrer">https://doi.org/10.5281/zenodo.11175164</a></li>
            <li>Nikolaou, P. (2024). Museums and the postdigital: revisiting challenges in the digital transformation of museums. Heritage, 7(3), 1784–1800. <a href="https://doi.org/10.3390/heritage7030084" target="_blank" rel="noreferrer">https://doi.org/10.3390/heritage7030084</a></li>
            <li>Furferi, R., Di Angelo, L., Bertini, M., Mazzanti, P., De Vecchis, K., & Biffi, M. (2024). Enhancing traditional museum fruition: current state and emerging tendencies. Heritage Science, 12(20). <a href="https://doi.org/10.1186/s40494-024-01139-y" target="_blank" rel="noreferrer">https://doi.org/10.1186/s40494-024-01139-y</a></li>
            <li>Agnolucci, L., Galteri, L., Bertini, M., & Del Bimbo, A. (2024, March 1). Reference-based Restoration of Digitized Analog Videotapes. 2024 IEEE/CVF Winter Conference on Applications of Computer Vision (WACV) (WACV), USA. <a href="https://doi.org/10.1109/WACV57701.2024.00168" target="_blank" rel="noreferrer">https://doi.org/10.1109/WACV57701.2024.00168</a>.</li>
            <li>Agnolucci, L., Galteri, L., Bertini, M., & Del Bimbo, A. (2024, March 1). ARNIQA: Learning Distortion Manifold for Image Quality Assessment. 2024 IEEE/CVF Winter Conference on Applications of Computer Vision (WACV) (WACV), USA. <a href="https://doi.org/10.1109/WACV57701.2024.00026" target="_blank" rel="noreferrer">https://doi.org/10.1109/WACV57701.2024.00026</a></li>
            <li>Rachabatuni, P., Principi, F., Mazzanti, P., & Bertini, M. (2024, April 17). Context-aware chatbot using MLLMs for Cultural Heritage. 15th ACM Multimedia Systems Conference (MMSys '24), Bari, Italy. <a href="https://doi.org/10.1145/3625468.3652193" target="_blank" rel="noreferrer">https://doi.org/10.1145/3625468.3652193</a></li>
            <li>Baldrati, A., Agnolucci, L., Bertini, M., & Del Bimbo, A. (2023, October 2). Zero-Shot Composed Image Retrieval with Textual Inversion. IEEE/CVF International Conference on Computer Vision (ICCV), 2023 (ICCV), Paris, France. <a href="https://doi.org/10.1109/ICCV51070.2023.01407" target="_blank" rel="noreferrer">https://doi.org/10.1109/ICCV51070.2023.01407</a></li>
            <li>Agnolucci, L., Baldrati, A., Todino, F., Becattini, F., Bertini, M., & Del Bimbo, A. (2023, February 10). ECO: Ensembling Context Optimization for Vision-Language Models. 2023 IEEE/CVF International Conference on Computer Vision Workshops (ICCVW) (ICCVW), Paris, France. <a href="https://doi.org/10.1109/ICCVW60793.2023.00299" target="_blank" rel="noreferrer">https://doi.org/10.1109/ICCVW60793.2023.00299</a></li>
            <li>Cartella, G., Baldrati, A., Morelli, D., Cornia, M., Bertini, M., & Cucchiara, R. (2023, September 5). OpenFashionCLIP: Vision-and-Language Contrastive Learning with Open-Source Fashion Data. Image Analysis and Processing – ICIAP 2023 (ICIAP), Udine, Italy. <a href="https://doi.org/10.1007/978-3-031-43148-7_21" target="_blank" rel="noreferrer">https://doi.org/10.1007/978-3-031-43148-7_21</a></li>
            <li>Cioni, D., Berlincioni, L., Becattini, F., & Del Bimbo, A. (2023, October 2). Diffusion Based Augmentation for Captioning and Retrieval in Cultural Heritage. 2023 IEEE/CVF International Conference on Computer Vision Workshops (ICCVW), 2023 (ICCVW), Paris, France. <a href="https://doi.org/10.1109/ICCVW60793.2023.00186" target="_blank" rel="noreferrer">https://doi.org/10.1109/ICCVW60793.2023.00186</a></li>
            <li>Agnolucci, L., Baldrati, A., Bertini, M., & Del Bimbo, A. (2023, October 27). Zero-Shot Image Retrieval with Human Feedback. The 31st ACM International Conference on Multimedia (MM '23), 2023 (MM), Ottawa, Canada. <a href="https://doi.org/10.1145/3581783.3612664" target="_blank" rel="noreferrer">https://doi.org/10.1145/3581783.3612664</a></li>
            <li>Baldrati, A., Bertini, M., Uricchio, T., & Del Bimbo, A. (2023). Composed Image Retrieval using Contrastive Learning and Task-oriented CLIP-based Features. ACM Transactions on Multimedia Computing, Communications, and Applications, 20(3). <a href="https://doi.org/10.1145/3617597" target="_blank" rel="noreferrer">https://doi.org/10.1145/3617597</a></li>
            <li>Papadas, S., Papapetrou, A., Frangoudes, F., Polydorou, A., Gavriil, C., Kyriakou, N., & Neokleous, K. (2024, January 1). ReInHerit a Museum: Enhancing Museum Experience and Broadening Audience Participation Through an Immersive Performance using Media-Art, and Augmented Reality. IMET - International Conference on Interactive Media, Smart Systems and Emerging Technologies (IMET), Barcelona (Spain). <a href="https://doi.org/10.2312/imet.20231262" target="_blank" rel="noreferrer">https://doi.org/10.2312/imet.20231262</a></li>
        </ol>
        </Container>

    </BaseLayout>
  )

}


export default Publications;